import React, { Component } from 'react';
// import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Icon from '@material-ui/core/Icon';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import Disclaimer from '../functions/Disclaimer';


//library.add(faCoffee)


class AboutDialog extends Component {


 render() {
    return (
	<Dialog open={this.props.open}  
	disableBackdropClick
	disableEscapeKeyDown
	fullWidth={true} 
	maxWidth="sm" 
	onClose={this.props.OnClose}>
		<DialogTitle id="scroll-dialog-title">
			Disclaimer
		</DialogTitle>
		<DialogContent dividers={true}>
				<DialogContentText>
					<Disclaimer />
				</DialogContentText>
		</DialogContent>
	        <DialogActions>
	          <Button onClick={() => this.props.OnClose() } color="secondary">
        	    I read and understood
	          </Button>
	        </DialogActions>

	</Dialog>
   );

}  

}


export default AboutDialog;
