// import logo from './logo.svg';
//import './App.css';
import React, { Component } from 'react';
import VaccineExplorer from './VaccineExplorer.js';
import Vaccines from './data/Vaccines.js';
import Patients from './data/Patients.js';
import Locations from './data/Locations.js';


class App extends Component  {

 constructor(props) {
	super(props);
	this.state = { 
		 patients: Patients.map((e, i) => {return { ...e, value: i }} ),
		 vaccines: Vaccines,
		 locations: Locations.map((e, i) => {return { ...e, value: i+1 }} ),
		};
 }

componentDidMount() {
//	console.log(process.env.NODE_ENV==="development");
}


 render() {
	return (
	<>
	<VaccineExplorer 
		 patients={this.state.patients} 
		 vaccines={this.state.vaccines} 
		 locations={this.state.locations} 
		/>
	</>
	)

  }

}

export default App;
