import {format, parseISO} from 'date-fns';


function ExtractFirstAvailableDate(value) {

	if(!!value)
		return format(parseISO(value),"yyyy-MM-dd");
//		return format(new Date(value),"yyyy-MM-dd");

	return "";
}


function ExtractEarliestAvailableDate(value) {

	if(!!value)
		return format(new Date(value),"yyyy-MM-dd");

	return "";
}


function ExtractFirstAvailableTime(value) {

	if(!!value)
		return format(parseISO(value),"HH:mm");

	return "";
}


export {ExtractFirstAvailableDate, ExtractEarliestAvailableDate, ExtractFirstAvailableTime};
