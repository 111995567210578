const Locations = [
	{
	 description: "Halifax (Dal)",
	 gis: { X: 453275.018, Y: 4942768.254 },
	},
	{
	 description: "Amherst (Hospital)",
	 gis: { X: 406938.683, Y: 5073005.671 },
	},
	{
	 description: "Antigonish (StFX)",
	 gis: { X: 578273.613, Y: 5052193.015 },
	},
	{
	 description: "Bridgewater (NSCC)",
	 gis: { X: 378162.032, Y: 4914193.884 },
	},
	{
	 description: "Port Hawkesbury (NSCC)",
	 gis: { X: 627105.202, Y: 5053944.176 },
	},
	{
	 description: "Springhill (NSCC)",
	 gis: { X: 417307.114, Y: 5055375.959 },
	},
	{
	 description: "Sydney (CBU)",
	 gis: { X: 724346, Y: 5117087 },
	},
	{
	 description: "Truro (NSCC)",
	 gis: { X: 477405.031, Y: 5022948.099 },
	},
	{
	 description: "Yarmouth (NSCC)",
	 gis: {  X: 250467.015, Y: 4860195.863 },
	},
]


export default Locations
