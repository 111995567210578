const firstDose = {
	 description: "First Dose",
	 appointmentId: "",
	 vaccine: "Pfizer|Moderna",
	}

var Patients = [
	firstDose
]


const PatientsDev = [
/*        {
         description: "GL",
         appointmentId: "722e02d0-020c-4d9f-ba3a-70775be645e1",
         vaccine: "Pfizer|Moderna",
	 gis: { X: 451349.465, Y: 4944710.579},
	 defaultValue: true,
        },
*/
]

if (process.env.NODE_ENV==="development")
	Patients = [ ...Patients, ...PatientsDev];

export default Patients;
