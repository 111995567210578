import React, { Component, useEffect } from 'react';
import { useTable, useFilters, useSortBy } from 'react-table';

import RegexFilter from '../functions/RegexFilter';
import '../css/ClinicsTable.css';



const DEFAULT_COLUMN = {
		width: "auto",
		disableFilters: true,
	};

const COLUMNS = [
	{
		Header: "First Available Appt",
		columns: [
		{
			Header: "Date",
			id: "FirstAvailableDate",
			accessor: "firstAvailableDate",
			Cell: ({ value }) => { return value !== "9999-01-01" ? value : "REFRESH"; },
		},
		{
			Header: "Time",
			id: "FirstAvailableTime",
			accessor: "firstAvailableTime",
		}
		]
	},
	{
		Header: "Location",
		columns: [
		{
			Header: "City",
			id: "city",
			accessor: "nameEn",
			Cell: ExtractCity,
			minWidth: "160px",
		},
		{
			Header: "Street",
			id: "street",
			accessor: "gisLocationString",
			Cell: ExtractStreet,
			minWidth: "210px",
		},
		{
			Header: "Distance",
			id: "distance",
			accessor: "distance",
			filter: 'between',
			Cell: ({ value }) => {return Math.round(value/1000); },
		},
		]
	},

/*		{
			Header: "Name",
			accessor: "nameEn",
		},
*/
	{
		Header: "Vaccine",
		id: "vaccine",
		accessor: "nameEn",
		Cell: ExtractVaccine,
		filter: RegexFilter,
	},
	{
		Header: "Full",
		accessor: "fullyBooked",
		Cell: ({ value }) => { return value ? 'Yes' : 'No' ; },
	}, 
]




function Extract(value, regex) {
 
 var match;

 if(!value)
	return value;

 try { 
	match  = value.match(regex);
 } catch (e) 
	{

 }

 if(!!match && match.length>1)
	 return match[1];

//  console.log("No match for " + regex + " in " + value);

  return value;


}


function ExtractVaccine ({ value }) {

 const regexVaccine = /.*\((.*)\)/;
 return Extract(value, regexVaccine);
}


function ExtractCity ({ value }) {

 const regexCommunity = /([a-zA-Z][^-]*) Community Immunization Clinic .*/;
 
 var city1 = Extract(value, regexCommunity);

 if(city1!==value)
	return city1;

 const regexCity = /([a-zA-Z][^-]*) - .*/;
 return Extract(value, regexCity);
}


function ExtractStreet ({ value }) {

 const regexStreet = /([^,]*).*/;
 return Extract(value, regexStreet);
}





/*

function SelectFullFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  
  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      <option value="true">Yes</option>
      <option value="false">No</option>
      ))}
    </select>
  )
}


function SelectVaccineFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(ExtractVaccine({value: row.values[id]}))
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

*/


function ClinicsTable({ cols, data, initFilters = [], sortby = [], vaccine = "", fullyBooked = "" , range = "" }) {
 

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    columns,
    prepareRow,
    setFilter,
//    setHiddenColumns,
//    state,
  } = useTable({
    columns: cols,
    data, 
    defaultColumn: DEFAULT_COLUMN,
    initialState: { 
//		filters: initFilters, 
		sortBy: sortby,
		},
    autoResetFilters: false
  }, useFilters, useSortBy)


  useEffect(() => {
	setFilter("fullyBooked",fullyBooked);

	if(fullyBooked !== "")
		columns.find(x => x.id === 'fullyBooked').toggleHidden(true);
	else
		columns.find(x => x.id === 'fullyBooked').toggleHidden(false);
	},[fullyBooked,setFilter,columns]);


  useEffect(() => {
	setFilter("vaccine",vaccine);

	if(vaccine !== "" && !vaccine.includes('|'))
		columns.find(x => x.id === 'vaccine').toggleHidden(true);
	else
		columns.find(x => x.id === 'vaccine').toggleHidden(false);

	},[vaccine,setFilter, columns]);


  useEffect(() => {

	if(!range || range === "")
		setFilter("distance",undefined);
	else
		setFilter("distance",[0,range]);

	}, [range, setFilter]);

/*  useEffect(() => {
	console.log(columns.find(x => x.id === 'fullyBooked'));
	console.log(state.filters);
	}, [state.filters]);
*/


 return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps({
		style: { 
			width: column.width, 
			minWidth: column.minWidth,
			maxWidth: column.maxWidth,
			},
                })}>
		{column.render('Header')}
		<div>{ column.canFilter ? column.render('Filter') : null}</div>
		</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps({
		style: { 
			width: cell.column.width, 
			minWidth: cell.column.minWidth,
			maxWidth: cell.column.maxWidth,
			},
                })}>
		{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
 )

}



class Clinics extends Component {

 constructor() {
	super();
	this.state = {
		filters: [
			{id: "fullyBooked", value: false},
			{id: "vaccine", value: "Pfizer" }
		],
		sortby : [
			{id: "FirstAvailableDate"},
			{id: "FirstAvailableTime"},
			{id: "distance"}, 
			{id: "city"},
		]
	};
}

 componentDidUpdate() {
//	console.log(this.props.data);
}


  render() {
    return (<ClinicsTable cols={COLUMNS} data={this.props.data} initFilters={this.state.filters} sortby={this.state.sortby} vaccine={this.props.vaccine}  fullyBooked={this.props.fullyBooked} range={this.props.range} />);
  }
}

export default Clinics;
