function Disclaimer() {

  return (
      <>

	<p>The  NS COVID Vaccine Explorer ("Website")  
	aggregates publicly 
	available information on COVID-19 vaccination appointments in 
	Nova Scotia, and allows
	you to search for the earliest available appointments
	in your vicinity. </p>

	<p>The Website's purpose  is
	to improve the efficiency of vaccination in Nova Scotia
	and to minimize waste of valuable vaccination slots 
	and public health resources.</p>


	<p>The information on the Website is provided AS IS, without any 
	representation or warranty about its accuracy. The Website 
	is <b>not</b> operated by or affiliated with any government or 
	health authority.</p>

	<p>Technical help with improving the Website is warmly 
	welcome.</p>

	<p>&copy; 2021 G&aacute;bor Luk&aacute;cs, Halifax, NS, 
	Canada</p>
     </>
  );

}


export default Disclaimer
