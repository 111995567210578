import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import SyncIcon from '@material-ui/icons/Sync';
import { withStyles } from "@material-ui/core/styles";


const styles = (theme) => ({ 
  button: {
    margin: theme.spacing(1),
  },
});




class ReloadButton extends Component {

 componentDidMount() {
//	console.log(this.props);
}


  render() {
        const { classes } = this.props;

    return (<Button 
		variant="outlined" 
		color="inherit"  
		startIcon={<SyncIcon />}
		className={classes.button}
		onClick={this.props.OnClick} disabled={this.props.disabled}>
			{this.props.text}
	</Button>);
  }
}

export default  withStyles(styles, { withTheme: true })(ReloadButton);
