import originalFetch from 'node-fetch';
import fetchRetry from 'fetch-retry';
import {ExtractFirstAvailableDate, ExtractFirstAvailableTime} from './Extract.js';

const fetch = fetchRetry(originalFetch);

//  let api_proxy = "http://localhost:8080";
 let appointments_path = "/fhir/v1/public/availability/17430812-2095-4a35-a523-bb5ce45d60f1?timezone=America%2FHalifax&preview=false"
// let appointments_url =  window.location.hostname === "localhost" ? appointments_path : api_proxy + appointments_path;

 let appointments_url =  appointments_path;


async function GetAppointments(id) {

 var url = appointments_url +  "&appointmentTypeId=" + id;


 const settings = { method: "GET" , 
	retries: 100, 
	retryDelay: 1000, 
	retryOn: [500,502,503,504]};

 return fetch(url, settings)

}

async function GetAppointmentsData(AppointmentTypeId, AppointmentId) {
 
 var url = appointments_url +  "&appointmentTypeId=" + AppointmentTypeId;


 if(!!AppointmentId && AppointmentId !== "" && AppointmentId !== null)
	url = url + "&appointmentId=" + AppointmentId;


 const settings = { method: "GET" , 
	retries: 100, 
	retryDelay: 1000, 
	retryOn: [500,502,503,504]};

// return GetAppointments(AppointmentId)

 return fetch(url, settings)
		.then(async res => {
			if(!res.ok) {
				var error = new Error();
				error.res = res;
				throw error;
				}

                        var x = await res.json();

			var data = {};

			if(!!x && !!x[0] && !!x[0].availabilities && !!x[0].availabilities[0] && !!x[0].availabilities[0].time) {
				data.availabilities = x[0].availabilities.map( y => y.time);
				data.firstAvailability = data.availabilities[0];
				data.firstAvailableDate = ExtractFirstAvailableDate(data.firstAvailability);
				data.firstAvailableTime = ExtractFirstAvailableTime(data.firstAvailability);
	                        }
			else {
				data.fullyBooked = true;
			}


                        return {data: data};    
                        })
		.catch( err => {
			if(!!err.res && !!err.res.url && !!err.res.status)
					console.log("Status " + err.res.status + ": " + err.res.url);
			else 
				console.log(err);

			return { data: {}, error: err};
			});

}

export {GetAppointments, GetAppointmentsData};
