// import logo from './logo.svg';
//import './App.css';
import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

import ReloadButton from './components/ReloadButton';
import Selector from './components/Selector';
import Clinics from './components/Clinics';
import AboutDialog from './components/AboutDialog';
import GetLocations from './functions/GetLocations';
import {GetAppointments,GetAppointmentsData} from './functions/GetAppointments';
import {ExtractFirstAvailableDate,  ExtractEarliestAvailableDate, ExtractFirstAvailableTime} from './functions/Extract.js';


 const development = process.env.NODE_ENV==="development";

const range_list = [
	{ description: "Any Distance",
	  value: ""
	},
	{ description: "within 10 km",
	  value: "10499"
	},
	{ description: "within 25 km",
	  value: "25499"
	},
	{ description: "within 50 km",
	  value: "50499"
	},
	{ description: "within 100 km",
	  value: "100499"
	},
	{ description: "within 150 km",
	  value: "150499"
	},
	{ description: "within 200 km",
	  value: "200499"
	},
]


const BarTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#b92327',
    },
  },
 typography: {
	button: {
		fontSize: '1rem',
		fontWeight: 700,
		textTransform: 'uppercase',
		fontFamily: ["Roboto Condensed", 'Arial', 'Helvetica', 'sans-serif']
	},
  },
});






class VaccineExplorer extends Component  {

 constructor(props) {
	super(props);
	this.state = { 
			gis: { X: 453275.018, Y: 4942768.254 }, // Halifax (Dal)
			GIS_index: 1,
			threshold: 150000,
			clinics: [], 
			range: "",
			patient_num: Math.max(this.props.patients.findIndex(x => !!x.defaultValue), 0),
			vaccine: "",
			fullyBooked: "false",
			loading: false,
			clinics_count: -1,
			clinics_loaded: 0,
			aboutOpen: !development,
			debug: false,
			
		};


	this.UpdateAll = this.UpdateAll.bind(this);
	this.ChangeVaccine = this.ChangeVaccine.bind(this);
	this.ChangePatient = this.ChangePatient.bind(this);
	this.ChangeFullyBooked = this.ChangeFullyBooked.bind(this);
	this.ChangeRange = this.ChangeRange.bind(this);
	this.ChangeLocation = this.ChangeLocation.bind(this);
	this.TestMe = this.TestMe.bind(this);

 }


 appointmentId() {
 	return this.props.patients[this.state.patient_num].appointmentId || "";
 }


async componentDidMount() {

        const patient_num = this.state.patient_num;

	var newState = {
			     vaccine: this.props.patients[patient_num].vaccine || "",
			     loading: true,
			     clinics_loaded: 0,
			    }; 

	if(!!this.props.patients[patient_num].gis && !!this.props.patients[patient_num].gis.X && !!this.props.patients[patient_num].gis.Y) 
		newState = { ...newState, 
				GIS_index: 0,
				gis: this.props.patients[patient_num].gis
			}

	await this.setState(prevState => { return newState } );




	if(development)
		this.UpdateClinics()
			.then((result) => { 
				this.setState({loading: false});
				});
	else
		this.UpdateAll();

}


async UpdateAll() {
	await this.setState(prevState => { return {
					loading: true,
					clinics_loaded: 0,
					}
				});

	this.UpdateClinics()
//		.then(() => {console.log("this.UpdateAllAppointments() is commented out"); return 0; })
		.then(() => this.UpdateAllAppointments())
		.then((result) => { 
				this.setState({loading: false});
				});

}




UpdateClinics() {

	 return GetLocations()
		.then(async res => {
			if(!res.ok) {
				var error = new Error();
				error.res = res;
				throw error;
				}

			var json = await res.json();
	
			if(!!json.results) {

				json.results.forEach((item,i) => {

					if(!!this.state.clinics[i] && item.id === this.state.clinics[i].id && !item.fullyBooked && !this.state.clinics[i].fullyBooked) {
						const prevClinic = this.state.clinics[i];
						item.availabilities =  prevClinic.availabilities;
						item.firstAvailability =  prevClinic.firstAvailability;
						item.firstAvailableDate =  prevClinic.firstAvailableDate;
						item.firstAvailableTime =  prevClinic.firstAvailableTime;
						}
					else {
//						item.firstAvailableDate="9999-01-01";
						item.firstAvailableDate= !!item.earliestAvailability ?  ExtractEarliestAvailableDate(item.earliestAvailability) : "9999-01-01";
						item.firstAvailableTime="";
						}

					if(!item.gisX || !item.gisY) {

						if(development) {
							console.log("This clinic has no gis:");
							console.log(item);
							}

						item.distance=-1;
						}
					else {
						item.distance = Math.sqrt((item.gisX - this.state.gis.X)**2 + (item.gisY - this.state.gis.Y)**2);
						}
					});

				await this.setState({ clinics: json.results} );
				}

			})
		.catch( err => {
			console.log(err);
			});

 }


async UpdateAppointments(i) {

		var x=await GetAppointments(this.state.clinics[i].appointmentTypeId)
		.then(async res => {
			if(!res.ok) {
				var error = new Error();
				error.res = res;
				throw error;
				}

			var json = await res.json();


			return json;	
			})
		.catch( err => {
			console.log(err);
			});

		var data = {};

		if(!!x && !!x[0] && !!x[0].availabilities && !!x[0].availabilities[0] && !!x[0].availabilities[0].time) {
			 data.availabilities = x[0].availabilities.map( y => y.time);
			 data.firstAvailability = data.availabilities[0];
			 data.firstAvailableDate = ExtractFirstAvailableDate(data.firstAvailability);
			 data.firstAvailableTime = ExtractFirstAvailableTime(data.firstAvailability);
			}
		else {
			data.fullyBooked = true;
		}


	await this.setState(prevState => {
	      const list = prevState.clinics.map((item, j) => {
	        if (j === i) {
	          return {...item, 
			  ...data
			};
	        } else {
	          return item;
	        }
	      });

      return {
        clinics: list
      };
    });
}



async CalculateDistance() {

	await this.setState(prevState => {
		const list = prevState.clinics.map((item, j) => {

				if(!!item.gisX && !! item.gisY)
					item.distance = Math.sqrt((item.gisX - this.state.gis.X)**2 + (item.gisY - this.state.gis.Y)**2);

				return item;
			});

		return {
	         clinics: list
	      	};
	});

}


async UpdateAllAppointments() {

 const vaccine = RegExp(this.state.vaccine,'i');

 const Indices = this.state.clinics.map((e, i) => [i, e])
	.filter(([i, e]) => !e['fullyBooked'])
	.filter(([i, e]) => vaccine.test(e['nameEn']) )
//	.filter(([i, e]) => e['nameEn'].includes(this.state.vaccine))
	.sort(([i, e], [j,f]) => {return e.distance > f.distance ? 1 : -1 ;});


 const indices_local = Indices.filter(([i,e]) => e.distance < this.state.threshold);
 const indices_remote = Indices.filter(([i,e]) => e.distance >= this.state.threshold);
// const indices_remote = [];

 this.setState(prevState => {return {clinics_count: Indices.length, clinics_loaded: 0 } });


 const chunk_size_local = 2;
 const concurrency_local = 8;

 const chunk_size_remote = 16;

 var pos;

/* for (pos = 0; pos < indices_local.length; pos += chunk_size_local)
	await this.UpdateSelectedAppointments(indices_local.slice(pos,pos+chunk_size_local).map(([i, _]) => i)); */

 for (pos = 0; pos < indices_local.length; pos += concurrency_local*chunk_size_local) {
	var tasks = [];

	for(var j = 0; j < concurrency_local ; j++)
		tasks[j] = this.UpdateSelectedAppointments(indices_local.slice(pos+j*chunk_size_local,pos+(j+1)*chunk_size_local).map(([i, _]) => i));
	await Promise.all(tasks);
	}

 for (pos = 0; pos < indices_remote.length; pos += chunk_size_remote)
	await this.UpdateSelectedAppointments(indices_remote
						.slice(pos,pos+chunk_size_remote)
						.map(([i, _]) => i));

}



async UpdateSelectedAppointments(ind) {


 var tmp = await Promise.all(ind.map( i => GetAppointmentsData(this.state.clinics[i].appointmentTypeId,this.appointmentId())));

 this.setState(prevState => {
	const list = prevState.clinics.map((item, j) => {

		 var k = ind.indexOf(j);

	         if (k > -1) {
			return {...item, ...tmp[k].data};
	         } else {
	          	return item;
	         }
		});
 
      return {
        clinics: list,
	clinics_loaded: prevState.clinics_loaded + ind.length,
      };
 });


}


async TestMe(event) {

 console.log("Test Me()");

// console.log(event.target.value);

}



ChangeRange(event) {

 this.setState(prevState => { return { range: Number.parseInt(event.target.value) || "" } });

}


ChangeVaccine(event) {

 this.setState(prevState => { return { vaccine: event.target.value } } );

}



ChangePatient(event) {

 const patient_num = event.target.selectedIndex;

 var newState = {
		patient_num: patient_num,
		vaccine: this.props.patients[event.target.selectedIndex].vaccine || "",
	};

 if(!!this.props.patients[patient_num].gis && !!this.props.patients[patient_num].gis.X && !!this.props.patients[patient_num].gis.Y) 
	newState = { ...newState, 
			gis: this.props.patients[patient_num].gis,
			GIS_index: 0,
		}
 else if(this.state.GIS_index === 0)
	newState =  { ...newState,
			GIS_index: 1,
			gis: this.props.locations.find(x => x.value === 1).gis,
		}

 this.setState(prevState => { return  newState } , this.CalculateDistance );

/* await this.setState(prevState => { return { 
					patient_num: event.target.selectedIndex,
					vaccine: this.props.patients[event.target.selectedIndex].vaccine || "",
				} } );

 if(!!this.props.patients[patient_num].gis && !!this.props.patients[patient_num].gis.X && !!this.props.patients[patient_num].gis.Y) {

	 this.setState(prevState => { return {
					gis: this.props.patients[patient_num].gis
				} } ); 
	}
*/

}

ChangeFullyBooked(event) {

  this.setState(prevState => { return { fullyBooked: event.target.value} } );
}


async ChangeLocation(event) {
 
 const i = parseInt(event.target.value);

 const obj = this.props.locations.find(x => x.value === i);

 const gis = i === 0 ? this.props.patients[this.state.patient_num].gis : obj.gis;

 this.setState(prevState => { return {
				 GIS_index: i,
				 gis: gis,
				}
			}, this.CalculateDistance);

}



 render () {
  return (
	<>
    <ThemeProvider theme={BarTheme}>
	<AppBar position="fixed" color="primary"><Toolbar>
		<ReloadButton text={this.state.loading ? "Loading" : "Refresh" } OnClick={this.UpdateAll} disabled={this.state.loading} />
		{this.props.patients.length > 1 ? <Selector name="patient_num" options={this.props.patients} OnChange={this.ChangePatient} Value={this.state.patient_num} disabled={this.state.loading} /> : "" }
		<Selector name="vaccine" options={this.props.vaccines} Value={this.state.vaccine} OnChange={this.ChangeVaccine} disabled={this.state.loading}/>
		{development ? <Selector name="fullyBooked" options={[{ description: "All", value: ""}, {description: "Full", value: "true" }, {description: "Available", value: "false" } ]} Value={this.state.fullyBooked} OnChange={this.ChangeFullyBooked}/> : "" }
		<Selector name="range" options={range_list} Value={this.state.range} OnChange={this.ChangeRange} />
		<Selector name="location" options={ !!this.props.patients[this.state.patient_num].gis ? [{description: "Home", value: 0},...this.props.locations] : this.props.locations } OnChange={this.ChangeLocation} Value={this.state.GIS_index} />
		{this.state.debug ? <button onClick={() => {console.log(this.state)}}>Show</button> : ""}
		{this.state.debug ? <button onClick={this.TestMe}>Test</button> : ""}
		<IconButton color="secondary" edge="end" onClick={() => this.setState({aboutOpen: true}) }> <InfoIcon /> </IconButton>
	</Toolbar>
	{ this.state.loading ?	<LinearProgress variant="determinate" color="secondary" value={this.state.clinics_loaded/this.state.clinics_count*100} /> : "" }
    </AppBar>
	<AboutDialog open={this.state.aboutOpen} OnClose={() => this.setState({ aboutOpen: false }) } />
    </ThemeProvider>
	<Toolbar />
	  <Container>
	    <Box my={2}>
		<Clinics data={this.state.clinics} vaccine={this.state.vaccine} fullyBooked={this.state.fullyBooked} range={this.state.range} />
	    </Box>
	   </Container>
	</>
   );
  }
}

export default VaccineExplorer;

