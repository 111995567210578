import originalFetch from 'node-fetch';
import fetchRetry from 'fetch-retry';

const fetch = fetchRetry(originalFetch);

// let api_proxy = "http://localhost:8080";
 let locations_path = "/fhir/v1/public/booking-page/17430812-2095-4a35-a523-bb5ce45d60f1/appointment-types?preview=false";
// let locations_url = window.location.hostname === "localhost" ? locations_path : api_proxy + locations_path;
 let locations_url = locations_path;

async function GetLocations() {

 const settings = { method: "GET" , 
	credentials: "omit", 
	retries: 10, 
	retryDelay: 1000, 
	retryOn: [500,502,503,504]};

 return fetch(locations_url, settings);
}

export default GetLocations;
