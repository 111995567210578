import React, { Component } from 'react';
import { Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
//    minWidth: 120,
  },
});



class Selector extends Component {

  render() {
	const { classes } = this.props;

	return (
		<FormControl 
			disabled={this.props.disabled}
 			size="small"
			margin="dense"
			variant="outlined"  
			color="secondary"
			className={classes.formControl}>
			<Select native
				onChange={this.props.OnChange} 
				value={this.props.Value}>
				{this.props.options.map((option, i) => (
				  <option key={i} value={option.value}>
				    {option.description}
				  </option>
				))}
			</Select>
		</FormControl>
	);

  }

}


export default withStyles(styles, { withTheme: true })(Selector)
