const anyVaccine = {
	 description: "Any Vaccine / Age",
	 value: "",
	}

const Vaccines = [
	anyVaccine,
	{
	 description: "Moderna (18+) / Pfizer (12+)", 
	 value: "mRNA-Pfizer|mRNA-Moderna" 
	}, 
	{
	 description: "Moderna (Age 18+)", 
	 value: "mRNA-Moderna" 
	},
	{
	 description: "Moderna (Age 2-5)", 
	 value: "Moderna 2 to 5 Years" 
	},
	{
	 description: "Moderna (Age < 2)", 
	 value: "Moderna Under 2 Years" 
	},
	{
	 description: "Moderna (Age 0.5-5)", 
	 value: "Moderna 6 Months to 5 Years" 
	},
	{
	 description: "Pfizer (Age 12+)", 
	 value: "mRNA-Pfizer" 
	}, 
	{
	 description: "Pfizer Pediatrics (Age 5-11)", 
	 value: "Pfizer Pediatrics" 
	}, 
/*	{
	 description: "AstraZeneca", 
	 value: "AstraZeneca" 
	} 
*/
]

export default Vaccines
